import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
    CenteredQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const AndachtUndDienst = ({ data }) => (
    <InnerPage
        title="Andacht und Dienst"
        description="Der Ausdruck des Wortes Gottes im Handeln"
    >
        <IntroHolder
            title="Andacht und Dienst"
            subtitle="Der Ausdruck des Wortes Gottes im Handeln"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Gemeinsames Handeln',
                'current-item': 'Andacht und Dienst',
            }}
        >
            <IntroQuote href="/gemeinsames-handeln/andacht-und-dienst/zitate">
                [...] die geistige Entwicklung der Seele [erfordert] nicht nur
                Gebet und Meditation [...], sondern auch aktiven Dienst für
                unsere Mitmenschen [...]
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Religiöses Leben bedeutet, dass Beten und Handeln untrennbar
                    miteinander verbunden sind.
                </p>
                <div>
                    <p>
                        Die Zuwendung zu Gott im{' '}
                        <Link to="/woran-bahai-glauben/natur-des-menschen/gebet-und-meditation/">
                            Gebet
                        </Link>{' '}
                        ist eine grundlegende Handlung, die in nahezu allen
                        Religionen essentiell ist. Nicht selten bittet der
                        Mensch im persönlichen Gebet um Beistand, Kraft oder
                        Gesundheit. Das Wort Gottes schenkt nicht nur Hoffnung,
                        sondern inspiriert auch zum Handeln. Die Hoffnung und
                        Zuversicht, die aus dem Wort Gottes geschöpft wird,
                        inspiriert zu einem Handeln, das auf das Gute
                        ausgerichtet ist: der Dienst. Dieser ermöglicht dem
                        Menschen, seine innewohnenden geistigen Eigenschaften,
                        Talente und Fähigkeiten weiterzuentwickeln und dadurch
                        Tugenden wie Liebe, Gerechtigkeit, Hoffnung, Demut,
                        Achtung und Großzügigkeit zu zeigen. Auf diese Weise ist
                        das Gebet der Keim des Dienstes und verleiht dem Handeln
                        Bedeutung.
                    </p>
                    <p>
                        <Link to="/gemeinsames-handeln/andacht-und-dienst/andachtsversammlungen/">
                            Andachten
                        </Link>{' '}
                        und Gebet bieten auch Gruppen von Menschen die
                        Gelegenheit, in gemeinsamer Runde die Worte Gottes zu
                        lesen und zu sprechen. So bewirkt die gemeinsame
                        Hinwendung zu heiligen Texten in Andachtsversammlungen
                        mit Freunden, Bekannten und Nachbarn eine zusätzliche
                        Verbundenheit. Sie entwickelt in der Gemeinschaft ein
                        stärkeres Bewusstsein für die geistigen und
                        gesellschaftlichen Bedürfnisse des Umfeldes und setzt
                        Kräfte für gemeinsames Handeln frei.
                    </p>
                    <p>
                        Eine weiterer Ausdruck gemeinsamer Andacht und
                        gemeinsamen Dienstes ist das Konzept des{' '}
                        <Link to="/gemeinsames-handeln/andacht-und-dienst/das-konzept-haus-der-andacht/">
                            Hauses der Andacht
                        </Link>{' '}
                        (Mashriqu’l-Adkhár).
                    </p>
                    <p>Es dient als ein Ort</p>
                    <CenteredQuote referenceNumber="2">
                        „für tiefes Nachdenken über die geistigen Wirklichkeiten
                        und die grundlegenden Fragen des Lebens, einschließlich
                        der persönlichen und gemeinschaftlichen Verantwortung
                        für die Verbesserung der Gesellschaft.”
                    </CenteredQuote>
                </div>
                <Reference>
                    <p>1. Universales Haus der Gerechtigkeit, 12.06.1984</p>
                    <p>
                        2. Botschaft des Universalen Hauses der Gerechtigkeit
                        vom 18.12.2014
                    </p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default AndachtUndDienst;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "andacht-und-dienst-img-feature.jpg" }
        ) {
            ...fluidImage
        }
    }
`;
